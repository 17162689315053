import Button from "antd-mobile/es/components/button";
import Dialog from "antd-mobile/es/components/dialog";
import ResultPage from "antd-mobile/es/components/result-page";
import ResultPage from "antd-mobile/es/components/result-page";
import BrandIcon from "~/components/icons/BrandIcon";
import XIcon from "~/components/ui/icons/XIcon";

export function closeConfirmModal() {
  Dialog.clear();
}

export function confirmModal(data) {
  return Dialog.show({
    // className: "inline-footer-actions",
    closeOnMaskClick: true,
    ...data,
  });
}

export function confirmDoubleAction(data) {
  const resultCardClass = "mt-2 text-base text-black max-w-lg w-full p-4 mb-4 text-center";
  const confirmModalContent = (
    <>
      <ResultPage
        className="grid-footer-btn min-h-full"
        status="success"
        icon={
          <div className="mb-4 h-24 w-24">
            <BrandIcon className="z-20 mb-4 p-2" color1="fill-white" />
          </div>
        }
        title={
          <>
            <h2 className="line-clamp-2 font-lexend text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
              {data.header} <br />
            </h2>
            <p className="mt-2 cursor-pointer text-sm text-gray-600">
              <span className="font-medium text-gray-600 hover:text-gray-500">{data.title}</span>
            </p>
          </>
        }
        // description={data.title}
        // secondaryButtonText={
        //   <>
        //     <XIcon className="h-6 w-6" />
        //   </>
        // }

        primaryButtonText={data.actions[0]?.text}
        onPrimaryButtonClick={data.actions[0]?.onClick}
        secondaryButtonText={data.actions[1]?.text}
        onSecondaryButtonClick={data.actions[1]?.onClick}
      //   <>
      //     <ChevronLeftIcon className="h-6 w-6" />
      //     Login
      //   </>
      // }
      // onSecondaryButtonClick={() => console.log("close")}
      >
        <div className="flex flex-col items-center">
          <div className="fixed left-0 top-0 flex h-16 w-16 flex-col items-center justify-center p-4" onClick={closeConfirmModal}>
            <XIcon className="h-full w-full" />
          </div>
          <ResultPage.Card className={resultCardClass}>{data.content}</ResultPage.Card>
          {data.actions.length > 2 &&
            data.actions.slice(2).map((action, index) => {
              return (
                <ResultPage.Card key={index} className={resultCardClass}>
                  <Button onClick={action.onClick} block={true}>
                    {action.text}
                  </Button>
                </ResultPage.Card>
              );
            })}
        </div>
      </ResultPage>
      {/* <div className="text-center">Are you sure you want to leave Forked?</div> */}
    </>
  );
  return Dialog.show({
    // className: "inline-footer-actions",
    closeOnMaskClick: data.closeOnMaskClick ?? true,
    content: confirmModalContent,
    className: "result-confirmation",
  });
}
